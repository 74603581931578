import axios from 'axios';
import { useContext } from 'react';
import { deepCopy } from 'components/shared/componentUtils';
import { baseUrl } from 'shared/utils';
import AuthContext from 'hooks/Auth/AuthContext';
import useComponents from 'components/hooks/Components/useComponents';
import useModal from 'components/hooks/Modal/useModal';

const useAuth = () => {
    const { auth, setAuth,
            loggedIn, setLoggedIn,
            logoutTimer, setLogoutTimer } = useContext(AuthContext);
    const { navTo } = useComponents();
    const { setModal } = useModal();

    const callAuth = async ( params, loginFail, authFail, props ) => {
        const URL = baseUrl() + '/auth/authenticate';
        try {
            const response = await axios.post(URL, params);
            handleAuthResponse(response, loginFail, props);
        } catch (err) {
            props.setLoading(false);
            authFail(err, props.setLoading);
        }
    }

    const handleAuthResponse = ( response, errCallback, props ) => {
        props.setLoading(false);
        const data = response.data;
        const role = data.role;
        const accessToken = data.accessToken;
        if (!accessToken) {
            errCallback(data);
            return;
        }
        const authData = {
            accessToken: accessToken,
            refreshToken: data.refreshToken,
            id: data.id.toString(),
            username: props.username,
            companyId: data.companyId,
            role: role,
        };
        authData.countryCode = data.country;
        authData.serviceTypes = data.serviceTypes;
        updateAuth(authData);
        setLoggedIn(true);
        document.getElementById('App').classList.remove('hide');
        const appElement = document.getElementById('App');
        Object.assign(appElement.style, {
            backgroundImage: '',
            backgroundSize: '',
            backgroundRepeat: '',
            backgroundPosition: ''
        })
        if (role.id === 6) {
            navTo('/pull-tabs', ['replace']);
        } else {
            navTo('/devices', ['replace']);
        }
    }

    const callRefresh = async (retryCount = 0) => {
        const URL = baseUrl() + '/auth/refresh-token';
        const refreshToken = getAuth().refreshToken;
        const reqConfig = {
            headers: { 'Refresh-Token': refreshToken },
            withCredentials: true
        };
        try {
            const response = await axios.post(URL, {}, reqConfig);
            return onRefreshSuccess(response); // Return the new access token
        } catch (err) {
            if (retryCount < 2) {
                return callRefresh(retryCount + 1);
            } else {
                callLogout();
            }
        }
    };

    const onRefreshSuccess = (response) => {
        const data = response.data;
        const newAuth = auth ? deepCopy(auth) : JSON.parse(localStorage.getItem('token'));
        const refreshToken = response.headers['x-refresh-token'] || getCookie('refreshToken') || getAuth().refreshToken ;
        newAuth.accessToken = data;
        newAuth.refreshToken = refreshToken;
        updateAuth(newAuth);
        return newAuth.accessToken;
    }

    const callLogout = async () => {
        const URL = baseUrl() + '/auth/logout';
        const accessToken = getAuth().accessToken;
        try {
            const reqConfig = {
                headers: {
                    'Access-Token': accessToken,
                },
                withCredentials: true
            };
            const response = await axios.post(URL, {}, reqConfig);
            callLogoutCallback();
        } catch (err) {
            callLogoutCallback();
        }
    }

    const callLogoutCallback = () => {
        window.history.replaceState(null, null, "/");
        localStorage.removeItem('token');
        setAuth(null);
        setLoggedIn(false);
        setModal(false);
        clearTimeout(logoutTimer);
        navTo('/login');
    }

    const getToken = () => {
        return getAuth()?.accessToken;
    }

    const getCountry = () => {
        return getAuth()?.countryCode;
    }

    const getCookie = ( name ) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            const encodedValue = parts.pop().split(';').shift();
            return decodeURIComponent(encodedValue);
        }
    }

    const getAuth = () => {
        if (auth) {
            return auth
        } else {
            const val = localStorage.getItem('token');
            try {
                return JSON.parse(val);
            } catch (error) {
                return null;
            }
        }
    }

    const updateAuth = (data) => {
        setAuth(data);
        localStorage.setItem('token', JSON.stringify(data))
    }

    return {
        auth, setAuth,
        loggedIn, setLoggedIn,
        logoutTimer,setLogoutTimer,
        getAuth, updateAuth,
        getToken,getCountry,
        callAuth, callRefresh, 
        callLogout, 
    }
}

export default useAuth;
