import './forgotPassword.scss';
import { useEffect, useRef, useState } from 'react';
import { text as t } from 'shared/text';
import useComponents from 'components/hooks/Components/useComponents';
import useInputs from 'components/hooks/Inputs/useInputs';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Link from 'components/Link/Link';
import logo from 'shared/img/primero-logo.png';
import bg from 'shared/img/login-bg.jpg';

const ForgotPassword = () => {
    const { navTo } = useComponents();
    const { inputValues, changeInputErrors, validateForm } = useInputs();
    const { callAPI } = useUtils();
    const [ emailSent, setEmailSent ] = useState();
    const runOnce = useRef(false);
    const skippedMount = useRef(false);
    const emailId = 'portal-forgot-password-email';

    useEffect(() => {
        if (runOnce.current) {return};
        runOnce.current = true;
        const appElement = document.getElementById('App');
        Object.assign(appElement.style, {
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        });
        document.addEventListener('keydown', onKeyPress);

        return () => {
            document.removeEventListener('keydown', onKeyPress);
            if (skippedMount.current) {
                Object.assign(appElement.style, {
                    backgroundImage: '',
                    backgroundSize: '',
                    backgroundRepeat: '',
                    backgroundPosition: '',
                });
            } else {
                skippedMount.current = true;
            }
        };
    }, []);

    const onKeyPress = ( event ) => {
        if (event.key === 'Enter') {
            const submitButton = document.getElementById('forgot-password-submit-button');
            submitButton.click();
        }
    };

    const onSubmit = (setLoading) => {
        const forgotPasswordEmail = inputValues[emailId];
        const validators = [
            {id: emailId, checks: ['required']},
        ];
        const errorsList = validateForm(validators);

        if (errorsList) {
            changeInputErrors(errorsList);
        } else {
            setLoading(true);
            const params = {
                email: forgotPasswordEmail,
            };
            const props = {
                email: forgotPasswordEmail,
                setLoading: setLoading,
            };
            callAPI('passwordForgot', callForgotPasswordCallback, params, props);
        }
    };

    const callForgotPasswordCallback = (data, props) => {
        props.setLoading(false);
        if (data.isSuccessful) {
            setEmailSent(true)
        } else {
            changeInputErrors({[emailId]: t.errorPasswordReset});
        }
    };

    const onBack = () =>{
        navTo('/login');
    }

    return (
        <div className='forgot-password'>
           <div className='forgot-password-form-container grid'>
                <div className='forgot-password-logo full centered'>
                    <img className='forgot-password-logo-image' src={logo} alt='logo'/>
                </div>
                {!emailSent ? <>
                    <div className='forgot-notice-container full centered'>
                        {t.forgotPasswordInstructions}
                    </div>
                    <form className='forgot-form full grid'>
                        <Input
                            classes='forgot-password-input half centered'
                            id={emailId}
                            startFocused={true}
                        />
                    </form>
                    <Button
                        id='forgot-password-submit-button'
                        classes='forgot-password-submit blue third centered'
                        type='submit'
                        onClick={(event, setLoading) => {onSubmit(setLoading)}}
                    >{t.submit}</Button>
                    <div className='forgot-cancel-link-container full centered'>
                        <Link link={'/login'}>{t.cancel}</Link>
                    </div>
                </> : <>
                    <div className='forgot-message-container full centered'>
                        <span className='resend-span'>{t.emailIsAlreadySent}</span>
                    </div>
                    <Button
                        id='forgot-password-submit-button'
                        classes=' blue third centered'
                        onClick={onBack}
                    >{t.back}</Button>
                </>}
            </div>
        </div>
    );
};

export default ForgotPassword;
