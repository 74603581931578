
import './resetPassword.scss';
import { deepCopy, hasInvalidSpecial, hasLower, hasNumber, hasUpper, hasValidSpecial } from 'components/shared/componentUtils';
import { useEffect, useRef, useState } from 'react';
import { text as t } from 'shared/text';
import useComponents from 'components/hooks/Components/useComponents';
import useInputs from 'components/hooks/Inputs/useInputs';
import useUtils from 'hooks/useUtils';
import Checkbox from 'components/Checkbox/Checkbox';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import Link from 'components/Link/Link';
import bg from 'shared/img/login-bg.jpg';
import logo from 'shared/img/primero-logo.png';

const ResetPassword = () => {
    const { navTo } = useComponents();
    const { inputValues, changeInputErrors, changeInputValues } = useInputs();
    const { callAPI, updateNotice } = useUtils();
    const runOnce = useRef(false);
    const skippedMount = useRef(false);
    const [ validPassword, setValidPassword ] = useState();
    const passwordId = 'portal-password';
    const confirmId = 'portal-password-confirm';
    const submitButtonId = 'portal-password-submit';

    useEffect(() => {
        if (runOnce.current) {
            return;
        }
        runOnce.current = true;
        const appElement = document.getElementById('App');
        Object.assign(appElement.style, {
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        });
        document.addEventListener('keydown', onKeyPress);

        return () => {
            document.removeEventListener('keydown', onKeyPress);
            if (skippedMount.current) {
                Object.assign(appElement.style, {});
            } else {
                skippedMount.current = true;
            }
        };
    }, []);

    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            const submitButton = document.getElementById(submitButtonId);
            submitButton.click();
        }
    };

    useEffect(() => {
        const val = inputValues?.[passwordId] ? inputValues[passwordId] : '';
        let invalid = 0;
        const newValues = deepCopy(inputValues);
        if (val.length >= 8) { newValues.minLength = true } else {
            newValues.minLength = false;
            invalid ++;
        }
        if (hasUpper(val)) { newValues.oneUppercase = true } else {
            newValues.oneUppercase = false;
            invalid ++;
        }
        if (hasLower(val)) { newValues.oneLowercase = true } else {
            newValues.oneLowercase = false;
            invalid ++;
        }
        if (hasNumber(val)) { newValues.oneNumber = true } else {
            newValues.oneNumber = false;
            invalid ++;
        }
        if (hasValidSpecial(val)) { newValues.oneSpecial = true } else {
            newValues.oneSpecial = false;
            invalid ++;
        }
        if (!hasInvalidSpecial(val)) { newValues.invalidSpecial = false } else {
            newValues.invalidSpecial = true;
            invalid ++;
        }
        newValues.passwordsMatch = val && inputValues[confirmId] && val === inputValues[confirmId]
        changeInputValues(newValues);
        setValidPassword(invalid === 0 ? true : false);
    }, [inputValues[passwordId], inputValues[confirmId]])

    const onSubmit = (event, setLoading) => {
        const password = inputValues[passwordId];
        const passwordConfirm = inputValues[confirmId];
        setLoading(true);
        let query = new URLSearchParams(window.location.search); // useParams not working
        let token = query.get('token');
        const params = {
            password: password,
            confirmation: passwordConfirm,
            secret: token,
        };
        const props = {
            confirmation: passwordConfirm,
            secret: token,
            setLoading: setLoading,
        };
        callAPI('passwordReset', passwordResetCallback, params, props);
    };

    const passwordResetCallback = (data, props) => {
        props.setLoading(false);
        if (data.isSuccessful) {
            updateNotice(data);
            navTo('/login', ['skipQueries']);
        } else {
            changeInputErrors({[passwordId]: data.errorMessage});
        }
    };

    return (
      <div className='reset-password'>
          <div className='reset-password-form-container grid'>
              <div className='reset-password-logo full centered'>
                  <img className='reset-password-logo-image' src={logo} alt='logo' />
              </div>
              <div className='full centered'>
                  {t.passwordResetInstructions}
              </div>
              <form className='full grid'>
                <Input
                    classes='reset-password-input half centered'
                    id={passwordId}
                    type='password'
                    label={t.newPassword}
                />
                <div className='third centered reset-padding'>
                    <Checkbox classes='full left password-req' id='minLength' displayOnly={true} label={t.passwordMinChar}/>
                    <Checkbox classes='full left password-req' id='oneUppercase' displayOnly={true} label={t.passwordOneUpper}/>
                    <Checkbox classes='full left password-req' id='oneLowercase' displayOnly={true} label={t.passwordOneLower}/>
                    <Checkbox classes='full left password-req' id='oneNumber' displayOnly={true} label={t.passwordOneNum}/>
                    <Checkbox classes='full left password-req' id='oneSpecial' displayOnly={true} label={t.passwordOneSpecial}/>
                    {inputValues?.invalidSpecial && <Checkbox classes='full left password-req' id='invalidSpecial' showFail={true} displayOnly={true} label={t.passwordInvalidChar}/>}
                </div>
                <Input
                    classes='reset-password-input half centered'
                    id={confirmId}
                    type='password'
                    label={t.confirmNewPassword}
                />
                <div className='third centered reset-padding'>
                    <Checkbox id='passwordsMatch' classes='full left password-req' displayOnly={true} label={t.passwordMismatch}/>
                </div>
              </form>
              <Button
                id={submitButtonId}
                classes='reset-password-submit blue third centered'
                type='submit'
                disabled={!validPassword || !inputValues.passwordsMatch}
                onClick={(event, setLoading) => {onSubmit(event, setLoading)}}
              >{t.submit}</Button>
              <div className='reset-cancel-link-container full centered'>
                  <Link link={'/login'}>{t.cancel}</Link>
              </div>
          </div>
      </div>
    );

};

export default ResetPassword;
