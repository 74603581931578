import './receiptModal.scss';
import { useEffect, useRef, useState} from 'react';
import { text as t } from 'shared/text';
import useModal from 'components/hooks/Modal/useModal';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';

const ReceiptModal = ({rowData}) => {
    const { removeModal } = useModal();
    const { callAPIGetBlob } = useUtils();
    const [ imageSrc, setImageSrc ] = useState(null);
    const [ showSpinner, setShowSpinner ] = useState(true);
    const runOnce = useRef();

    useEffect(() => {
        if (runOnce.current) {return}
        runOnce.current = true;
        callAPIGetBlob('receiptImageGet', receiptImageGetCallback, '', {id: rowData.id})
    }, [])

    const receiptImageGetCallback = (data) => {
        setShowSpinner(false);
        if (data instanceof Blob) {
            const url = URL.createObjectURL(data);
            setImageSrc(url);
        }
    }

    return (
        <Modal classes='receipt-modal'>
            {showSpinner && <span className='spinner'/>}
            {imageSrc && <img src={imageSrc} alt={t.receipt} className='receipt-image'/>}
            <Button classes='receipt-close green' onClick={()=>{removeModal(false)}}>{t.close}</Button>
        </Modal>
    )
}

export default ReceiptModal;
