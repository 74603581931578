import './deviceTransactions.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deviceTransactionsLayout as layout } from './DeviceTransactionsLayout';
import { getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import * as e from 'shared/excel/excel';
import useInputs from 'components/hooks/Inputs/useInputs';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/Profile/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import TableListed from 'components/Tables/TableListed';
import ReceiptModal from 'modals/FlowModals/ReceiptModal/ReceiptModal';

const DeviceTransactions = () => {
    const { id } = useParams();
    const { updateDropdown, dropdownOriginals } = useInputs();
    const { addModal } = useModal();
    const { setHeader, updateDeviceBreadcrumbs } = useProfile();
    const { getURLParams, updateTable, updateDropdowns, 
            configureExcelDates, configureExcelReport } = useTables();
    const { callAPIGet } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ tableRecords, setTableRecords ] = useState();
    const [ currentRange, setCurrentRange ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ transactionTypes, setTransactionTypes ] = useState();
    const [ masked, setMasked ] = useState(true);    
    const tableId = 'device-transactions-' + id;
    const header = useRef();
    const typeId = tableId + '-typeId';
    const allTypes = [
        {id: 0, description: t.notSet},
        {id: 1, description: t.purchase},
        {id: 2, description: t.refund},
        {id: 3, description: t.releaseEscrow},
        {id: 4, description: t.noPurchaseNecessary},
        {id: 5, description: t.redemption},
        {id: 6, description: t.promo}
    ]
    const posTypes = [
        {id: 1, description: t.purchase},
        {id: 2, description: t.refund},
        {id: 5, description: t.redemption},
    ]

    layout.navigation.receipt.onClick = (pageId, rowData) => {addModal(<ReceiptModal rowData={rowData}/>)}

    useEffect(() => {
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        setCurrentRange(range);
        getReportData(range);
        callAPIGet('deviceDetails', deviceDetailsCallback, {terminalId: id});
    }, []);

    useEffect(() => {
        const urlParams = getURLParams(tableId);
        if (urlParams.dropdowns) {
            const sorts = urlParams.dropdowns?.[typeId];
            sorts && updateDropdown(typeId, sorts.value, sorts.label);
        }
    }, [transactionTypes]);

    const deviceDetailsCallback = (data) => {
        const type = data.terminalType;
        setPageData(data);
        header.current = type.name + ': ' + data.terminalId
        setHeader(header.current);
        updateDeviceBreadcrumbs(data);
        setTransactionTypes(getTypes(type.id));
    }

    const getTypes = (type) => {
        switch(type) {
            case 3:
                allTypes.splice(6,1);
                allTypes.splice(4,1);
                return allTypes;
            case 4: 
                return posTypes;
            default:
                return allTypes;
        }
    }

    const getReportData = (range) => {
        updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        params.terminalId = id;
        callAPIGet('report-DeviceTransactions', deviceTransactionsCallback, params);
    }
    
    const deviceTransactionsCallback = (data) => {
        setTableRecords(data?.tableData ? data.tableData : []);
        setMasked(false);
    }

    const onTransactionTypeSelect = (data) => {
        data.searchData = {
            dropdownProp: 'label',
            recordProp: 'transactionType'
        }
        updateDropdowns(tableId, typeId, data);
    }

    const onDateRangePick = (range) => {
        getReportData(range);
        setCurrentRange(range);
    }

    const onExportToExcel = () => { 
        const title = `${header.current} ${t.transactions} ${t.report}`
        const dateInfo = configureExcelDates(tableId, currentRange, {
            title: title,
            columns: ['locationName', 'transactionTypes', 'startDate', 'endDate'],
            data:[{ 
                transactionTypes: dropdownOriginals?.[typeId] || t.all,
                locationName: pageData?.location?.label
            }],
            configs: {
                formats: {locationName: 'text-wrap'}
            }
        });
        const [tableInfo, sizes] = configureExcelReport(tableId, t.locations, layout);
        e.exportToFile(title, [dateInfo, tableInfo], sizes);
    }

    return (<TableListed
        id={tableId}
        classes='full'
        data={tableRecords}
        layout={layout}
        masked={masked}
        searchBarContent={<>
            <Dropdown
                id={typeId}
                classes='quarter'
                label={t.transactionTypes}
                data={transactionTypes}
                masked={masked}
                labelProp='description'
                nullable={true}
                hideErrors={true}
                callback={onTransactionTypeSelect}/>
            <DateRangePicker
                classes='quarter'
                defaultVal={defaultDates}
                label={t.dateRange}
                disabled={masked}
                callback={onDateRangePick}
            />
            <Button 
                classes='green sixth match-labeled-input'
                disabled={masked}
                onClick={onExportToExcel}
            >{t.exportToExcel}</Button>
        </>}
    />)
}

export default DeviceTransactions;
