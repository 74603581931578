import { text as t } from 'shared/text';

export const locationPlayersLayout = {

    tableHeaders: {
        phoneNumber: t.phoneNumber,
        playerPin: t.playerPIN,
        cashableBalance: t.awards,
        nonCashableBalance: t.sweepsCoins,
        lastUpdated: t.lastCaptureFromKiosk
    },

    sizes: {
        phoneNumber: 'mid',
        playerPin: 'xs',
        cashableBalance: 'mid',
        nonCashableBalance: 'mid',
        lastUpdated: 'xl'
    },

    layouts: {
        phoneNumber: 'text-left',
        playerPin: 'text-left',
        cashableBalance: 'text-right',
        nonCashableBalance: 'text-right',
        lastUpdated: 'text-left'
    },

    formats: {
        phoneNumber: 'phone',
        playerPin: 'lastFour',
        cashableBalance: 'currency-decimal',
        nonCashableBalance: 'number',
        lastUpdated: 'standard-date'
    },
    
    navigation: {
        playerPin: {
            label: 'playerPin',
            onClick: true,
            data: [
                {type: 'text', val: 'location'},
                {type: 'var', val: 'id'},
                {type: 'text', val: 'player'},
                {type: 'prop', val: 'playerPin'},
            ]
        }
    },

    subtotals: {
        groupBy: 'all',
        fields: {
            phoneNumber: 'header',
            cashableBalance: 'total',
            nonCashableBalance: 'total',
        },
        formats: {
            cashableBalance: 'currency-decimal',
            nonCashableBalance: 'number',
        }
    }
}
