import { text as t } from 'shared/text'

export const locations = {
    page: t.locations,

    defaultSort: {
        prop: 'id',
        desc: true
    },

    tableHeaders: {
        name: t.name,
        id: t.id,
        city: t.city,
        stateCode: t.state,
        companyName: t.company,
    },

    sizes: {
        name: 'large',
        id: 'small',
        city: 'small',
        stateCode: 'mid',
        companyName: 'mid',
    },

    layouts: {
        name: 'text-left',
        id: 'text-left',
        city: 'text-left',
        stateCode: 'text-left',
        companyName: 'text-left',
    },

    navigation: {
        name: {
            label: 'name',
            data:[
                {type: 'text', val: 'location'},
                {type: 'prop', val: 'id'},
            ]
        },
        companyName: {
            label: 'companyName',
            data:[
                {type: 'text', val: 'company'},
                {type: 'prop', val: 'companyId'},
            ]
        }
    },

    rowCount: 20,
}
