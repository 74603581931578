import { useEffect, useRef, useState} from 'react';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/Profile/useProfile';
import Modal from 'components/Modal/Modal';
import CompanyDetails from 'pages/Companies/CompanyDetails/CompanyDetails';
import CompanySettings from 'pages/Companies/CompanySettings/CompanySettings';

const AddCompanyModal = () => {
    const { clearAllInputs } = useInputs();
    const { setModal } = useModal();
    const { setRefreshPage } = useProfile();
    const [ companyId, setCompanyId ] = useState();
    const runOnce = useRef();

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
    }, []);

    const onSubmit = (data) => {
        setCompanyId(data);
        setRefreshPage(true);
    }

    const onCancel = (data) => {
        setModal(false);
        clearAllInputs();
    }

    const onSettingsCancel = () => {
        setRefreshPage(true);
        setModal(false);
    }

    return (
        <Modal title={`${companyId ? t. configureSettingsOptional : t.addCompany}`} classes='add-modal'>
            {!companyId && <CompanyDetails newCompany={true} newSubmit={onSubmit} newCancel={onCancel}/>}
            {/* {companyId && <CompanySettings newCompany={companyId} newCancel={onSettingsCancel}/>} */}
        </Modal>
    )
}

export default AddCompanyModal;
