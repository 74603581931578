import './gamePerformanceReports.scss';
import { useEffect, useRef, useState } from 'react';
import { gamePerformanceReportsLayout as layout } from './GamePerformanceReportsLayout';
import { getDefaultDateRange } from 'components/shared/componentUtils';
import { reportsData, deviceTypes } from 'shared/data';
import { text as t } from 'shared/text';
import * as e from 'shared/excel/excel';
import useInputs from 'components/hooks/Inputs/useInputs';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import TablePaged from 'components/Tables/TablePaged';
const tableId = 'device-game-performance';
const typeId = 'TerminalTypeId';

const GamePerformanceReports = () => {
    const { inputValues, dropdownOriginals, updateDropdown } = useInputs();
    const { getURLParams, updateDropdowns, updateTable,
            configureExcelDates, configureExcelReport} = useTables();
    const { callAPIGet } = useUtils();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ currentRange, setCurrentRange ] = useState();
    const [ masked, setMasked ] = useState(true);
    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true; 
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        setCurrentRange(range);
    }, []);

    useEffect(() => {
        if (inputValues?.[reportsData.dropdownId]){
            const urlParams = getURLParams(tableId);
            if (urlParams.dropdowns) {
                const sorts = urlParams.dropdowns?.[typeId];
                sorts && updateDropdown(typeId, sorts.value, sorts.label);
            }
        }
    }, [inputValues?.[reportsData.dropdownId]])

    const getReportsData = (data, callback) => {
        setMasked(true);
        const params = {
            Search: data.searchTerm,
            PageIndex: data.currentPage,
            PageSize: data.rowCount,
            OrderBy: data.orderBy,
            Desc: data?.desc,
        }
        if (data.periodStart) {
            params.PeriodStart = data.periodStart;
            params.PeriodEnd = data.periodEnd;
        }
        data.callback = callback;
        callAPIGet('report-GamePerformance', gamePerformanceCallback, params, data);
    }
    
    const gamePerformanceCallback = (data, props) => {
        setMasked(false);
        if (data.data && props.callback) {
            props.records = data.data;
            props.pageCount = data.pageCount ? data.pageCount : '';
            props.callback(props);
        }
    }

    const onDeviceTypeSelect = (data) => {
        const newData = {
            value: data.value,
            label: data.label
        }
        updateDropdowns(tableId, typeId, newData);
    }

    const onDateRangePick = (range) => {
        updateTable('selectedRows', tableId, null);
        setCurrentRange(range);
        setTimeout(() => {
            updateTable('dates', tableId, range);
        }, 0)
    }

    const onExportToExcel = () => { 
        const title = `${t.gamePerformance} ${t.report}`
        const dateInfo = configureExcelDates(tableId, currentRange, {
            title: title,
            columns: ['deviceType', 'startDate', 'endDate'],
            data:[{deviceType: dropdownOriginals?.[typeId] || t.all}],
        });
        layout.formats.locationName= 'text-wrap';
        layout.formats.deviceType= 'text-wrap';
        const [tableInfo, sizes] = configureExcelReport(tableId, t.games, layout);
        e.exportToFile(title, [dateInfo, tableInfo], sizes);
    }

    return (<TablePaged
        id={tableId}
        layout={layout}
        defaultDates={defaultDates}
        apiId='report-GamePerformance'
        apiCall={getReportsData}
        searchBarContent={<>
        <Dropdown
            id={typeId}
            classes='quarter'
            label={t.terminalType}
            data={deviceTypes}
            masked={masked}
            nullable={t.all}
            hideErrors={true}
            callback={onDeviceTypeSelect}
        />
        <DateRangePicker
            classes='quarter'
            defaultVal={defaultDates}
            label={t.dateRange}
            stateId={tableId}
            callback={onDateRangePick}/>
        <Button
            classes='green sixth match-labeled-input'
            disabled={masked}
            onClick={onExportToExcel}
        >{t.exportToExcel}</Button>
        </>}
    />)
}

export default GamePerformanceReports;