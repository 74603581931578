import './companyDetails.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deepCopy } from 'components/shared/componentUtils';
import { companyDetailsLayout as layout } from './CompanyDetailsLayout';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import CardSection from 'app-components/Cards/CardSection/CardSection';
import Checkbox from 'components/Checkbox/Checkbox';
import Dropdown from 'components/Dropdown/Dropdown';
import Input from 'components/Input/Input';
import Page from 'app-components/Page/Page';

const CompanyDetails = ({newCompany, newSubmit, newCancel}) => {
    const { id } = useParams();
    const { inputValues, updateNestedInputData, 
            reNestedData, validateForm,
            updateInputErrors, changeInputErrors } = useInputs();
    const { removeModal } = useModal();
    const { callAPI, callAPIGet, updateCompanyTabs, 
            hasRank, updateNotice } = useUtils();
    const { setHeader, companyTabs } = useProfile();
    const [ pageData, setPageData ] = useState();
    const [ states, setStates ] = useState();
    const [ countries, setCountries ] = useState();
    const [ masked, setMasked ] = useState(true);    
    const [ copied, setCopied ] = useState([false]);
    const [ copyTrigger, setCopyTrigger ] = useState(false);
    const [ disabled, setDisabled ] = useState(false);
    const mainId1 = 'companyInfo';
    const mainId2 = 'principalContact';
    const permitted = hasRank('pullTabsVendor', true)
    const runOnce = useRef(false);
    const setOnce = useRef(false);
    const copyId1 = useRef('billingInfo');
    const copyId2 = useRef('billingContact');

    const dropdownList = {
        ['companyInfo-state']: {list: 0, labelProp: 'stateCode'},
        ['billingInfo-state']: {list: 0, labelProp: 'stateCode'},
        ['companyInfo-country']: {list: 1, valueProp: 'countryCode', labelProp: 'countryCode'},
        ['billingInfo-country']: {list: 1, valueProp: 'countryCode', labelProp: 'countryCode'}
    }

    useEffect(() => {
        if (runOnce.current) {return}
        runOnce.current = true;
        updateCompanyTabs();
        callAPI('statesGet', statesGetCallback);
        callAPI('countriesGet', countriesGetCallback);
        callAPI('companyDetails', companyDetailsCallback, {id: newCompany ? -1 : id});
    }, []);

    useEffect(()=> {
        if (pageData && states && countries && !setOnce.current) {
            setOnce.current = true;
            const update = updateNestedInputData(pageData, dropdownList, [states, countries]);
            update && setMasked(false);
        }
    }, [pageData, states, countries])

    useEffect(() => {
        if (copied[0]) {
            copyId1.current = mainId1
            copyId2.current = mainId2
        } else {
            copyId1.current = 'billingInfo'
            copyId2.current = 'billingContact'
        }
        setCopyTrigger(!copyTrigger);
    }, [copied])

    useEffect(()=> {
        pageData && setTimeout(()=> {
            const newStates = reNestedData(deepCopy(pageData));
            copyData(newStates);
        }, 1)
    }, [copyTrigger])

    const statesGetCallback = (data) => {
        setStates(data?.states ? data.states : [])
    }

    const countriesGetCallback = (data) => {
        setCountries(data?.countries ? data.countries : []);
    }

    const companyDetailsCallback = (data) => {
        const details = data?.companyDetailsData;
        if (details) {
            setHeader(details.companyInfo?.companyName);
            setPageData(details);
            if (details?.duplicateData?.duplicateCheckbox) {
                setCopied([true]);
            } 
        } else {
            setMasked(false);
        }
    };

    const onCopyCheck = (data) => {
        const value = data.value;
        setCopied([value]);
    };

    const onSubmit = (event, setLoading) => {
        setLoading(false);
        const errorsList = validateForm(layout.validators);
        const companyName = inputValues['companyInfo-companyName'];
        const props = {
            event: event, 
            setLoading: setLoading,
            errorsList: errorsList
        };
        if (errorsList && !companyName) {
            updateNotice('formErrors', props);
            changeInputErrors(errorsList)
        } else if (errorsList && companyName) {
            newCompany ? checkCompanyNameUnique(props) : changeInputErrors(errorsList);
        } else {
            newCompany ? checkCompanyNameUnique(props) : submitCompanyData(props);
        }
    };

    const checkCompanyNameUnique = (props) => {
        props.setLoading(true);
        setDisabled(true);
        callAPIGet('companyNameUnique', companyNameUniqueCallback, {name: inputValues['companyInfo-companyName']}, props)
    }

    const companyNameUniqueCallback = (data, props) => {
        let errorsList = props.errorsList;
        if (data?.isSuccessful) {
            if (data?.isUnique && !errorsList) {
                submitCompanyData(props);
            } else {
                props.setLoading(false);
                setDisabled(false);
                if (!errorsList) {errorsList = {}};
                if (!data.isUnique) {errorsList['companyInfo-companyName'] = t.nameAlreadyTaken};
                updateNotice('formErrors', props);
                changeInputErrors(errorsList);
            }
        } else {
            props.setLoading(false);
            setDisabled(false);
            updateNotice(data, props);
        }
    }

    const submitCompanyData = (props) => {
        props.setLoading(true);
        setDisabled(true);
        const newData = reNestedData(pageData);
        if (copied[0]) {
            newData.billingInfo = deepCopy(newData.companyInfo)
            newData.billingContact = deepCopy(newData.principalContact)
        }
        if (newData.companyInfo.state) {newData.companyInfo.state = newData.companyInfo.state.toString()};
        if (newData.billingInfo.state) {newData.billingInfo.state = newData.billingInfo.state.toString()};
        const params = { companyDetailsData: newData };
        callAPI('companyUpdate', companyUpdateCallback, params, props);
    }

    const companyUpdateCallback = (data, props) => {
        props.setLoading(false);
        setDisabled(false);
        if (newCompany && data.isSuccessful) {
            updateNotice(data);
            newSubmit(data);
            removeModal();
        } else {
            updateNotice(data, props);
        }
    };

    const onCancel = () => {
        newCancel();
    }

    const copyData = (newStates) => {
        newStates.billingInfo =  deepCopy(newStates.companyInfo);
        newStates.billingContact = deepCopy(newStates.principalContact);
        setPageData(newStates);
        const update = updateNestedInputData(newStates, dropdownList, [states, countries]);
        update && setMasked(false);
    }

    const onCompanyNameBlur = (data) => {
        (newCompany || (data.value && data.value !== pageData[data.section][data.line])) && callAPIGet('companyNameUnique', uniqueBlurCallback, {name: data.value})
    }
    const uniqueBlurCallback = (data) => {
        !data?.isUnique && updateInputErrors('companyInfo-companyName', t.nameAlreadyTaken);
    }

    return (
        <Page
            subPage={newCompany ? '' : t.details}
            tabs={newCompany ? [] : companyTabs}
            contentClasses='grid'
        >
            <Card hideLabel={true} classes='full'>
                <CardSection label={t.companyInfo} classes='bottom-border half'>
                    <Input  label={t.companyName} section={mainId1} line='companyName' classes='full' 
                            masked={masked} noPermission={!permitted} onBlurCallback={onCompanyNameBlur}/>
                    <Input  label={t.address}  section={mainId1} line='addressOne' classes='full' 
                            type='allCaps' masked={masked} noPermission={!permitted}/>
                    <Input  label={t.addressTwo} section={mainId1} line='addressTwo' classes='full' 
                            type='allCaps' masked={masked} noPermission={!permitted}/>
                    <Input  label={t.city} section={mainId1} line='city' classes='half'  
                            type='allCaps' masked={masked} noPermission={!permitted}/>
                    <Dropdown label={t.state} section={mainId1} line='state' classes='half' data={states} 
                            labelProp='stateCode' masked={masked} noPermission={!permitted}/>
                    <Input  label={t.zip} section={mainId1} line='zip' classes='half'  
                            type='zip' masked={masked} noPermission={!permitted}/>        
                    <Dropdown label={t.country} section={mainId1} line='country' classes='half' data={countries} valueProp='countryCode'
                            labelProp='countryCode' masked={masked} noPermission={!permitted}/>      
                </CardSection>
                <CardSection
                    label={t.billingInfo} classes='bottom-border half'
                    labelBarAddition={
                        <Checkbox   label={t.duplicateCheckbox} section='duplicateData' line='duplicateCheckbox' classes='right' 
                                    noPermission={!permitted} callback={onCopyCheck}/>            
                    }>
                    <Input  label={t.companyName} section={copyId1.current} line='companyName'  classes='full' 
                            disabled={copied?.[0]} masked={masked} noPermission={!permitted}/>   
                    <Input  label={t.address} section={copyId1.current} line='addressOne' classes='full' 
                            type='allCaps' disabled={copied?.[0]} masked={masked} noPermission={!permitted}/>   
                    <Input  label={t.addressTwo} section={copyId1.current} line='addressTwo' classes='full'
                            type='allCaps' disabled={copied?.[0]} masked={masked} noPermission={!permitted}/> 
                    <Input  label={t.city} section={copyId1.current} line='city' classes='half'  
                            type='allCaps' disabled={copied?.[0]} masked={masked} noPermission={!permitted}/> 
                    <Dropdown label={t.state} section={copyId1.current} line='state' classes='half' data={states} 
                            labelProp='stateCode' disabled={copied?.[0]} masked={masked} noPermission={!permitted}/>
                    <Input  label={t.zip} section={copyId1.current} line='zip'  classes='half' 
                            type='zip' disabled={copied?.[0]} masked={masked} noPermission={!permitted}/> 
                    <Dropdown label={t.country} section={copyId1.current} line='country' classes='half' data={countries} valueProp='countryCode'
                            labelProp='countryCode' disabled={copied?.[0]} masked={masked} noPermission={!permitted}/>
                </CardSection>
                <CardSection label={t.principalContact} classes='half'>
                    <Input  label={t.contactName} section={mainId2} line='name' classes='two-thirds'  
                            maxLength={50} masked={masked} noPermission={!permitted}/>
                    <Input  label={t.title}  section={mainId2} line='title' classes='third' 
                            maxLength={25} masked={masked} noPermission={!permitted}/>
                    <Input  label={t.phoneNumber} section={mainId2} line='phone' classes='half' 
                            type='phone' masked={masked} noPermission={!permitted}/>
                    <Input  label={t.email} section={mainId2} line='email' classes='half' 
                            maxLength={50} masked={masked} noPermission={!permitted}/>
                </CardSection>
                <CardSection label={t.billingContact} classes='half'>
                    <Input  label={t.contactName} section={copyId2.current} line='name' classes='two-thirds' 
                            maxLength={50} disabled={copied?.[0]} masked={masked} noPermission={!permitted}/> 
                    <Input  label={t.title} section={copyId2.current} line='title' classes='third'
                            maxLength={50} disabled={copied?.[0]} masked={masked} noPermission={!permitted}/> 
                    <Input  label={t.phoneNumber}  section={copyId2.current} line='phone' classes='half' 
                            type='phone' disabled={copied?.[0]} masked={masked} noPermission={!permitted}/> 
                    <Input  label={t.email} section={copyId2.current} line='email' classes='half'  
                            maxLength={50} disabled={copied?.[0]} masked={masked} noPermission={!permitted}/>
                </CardSection>
            </Card>
            {newCompany && <div className='full modal-buttons-container grid'>
                <Button 
                    classes='red quarter'
                    disabled={disabled}
                    onClick={onCancel}
                    >{t.cancel}
                </Button>
                <Button classes='green quarter last' 
                    type='submit'
                    onClick={(event, setLoading) => onSubmit(event, setLoading)}
                    >{t.submit}
                </Button>
            </div>}
            {!newCompany && permitted && <Button 
                classes='green quarter last' 
                type='submit'
                onClick={(event, setLoading) => onSubmit(event, setLoading)}
                >{t.submit}
            </Button>}
        </Page>
    );
};

export default CompanyDetails;
