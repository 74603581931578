import './locationSettings.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { deepCopy, isValid } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useAuth from 'hooks/Auth/useAuth';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import Checkbox from 'components/Checkbox/Checkbox';
import Dropdown from 'components/Dropdown/Dropdown';
import Input from 'components/Input/Input';
import Page from 'app-components/Page/Page';

const LocationSettings = () => {
    const { id }  = useParams();
    const { getCountry } = useAuth();
    const { inputValues, changeInputValues, 
            changeInputErrors, clearAllInputs,
            updateNestedInputData } = useInputs();
    const { locationTabs, updateLocationBreadcrumbs, 
            updateLocationHeader } = useProfile();
    const { callAPI, callAPIGet,
            hasRank, updateNotice,
            updateLocationTabs } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ alerts, setAlerts ] = useState();
    const [ alertsContacts, setAlertsContacts ] = useState();
    const [ lowsFirstIndex, setLowsFirstIndex ] = useState(0); 
    const [ lowsSecondIndex, setLowsSecondIndex ] = useState(0);
    const [ tiltsFirstIndex, setTiltsFirstIndex ] = useState(0); 
    const [ tiltsSecondIndex, setTiltsSecondIndex ] = useState(0);
    const [ masked, setMasked ] = useState(true);    
    const permitted = hasRank('owner', true);
    const runOnce = useRef(false);
    const setOnce = useRef(false);
    const deviceTypes = useRef();
    const country = useRef(getCountry());

    useEffect(() => {
        if (runOnce.current && pageData?.id === id) {return};
        runOnce.current = true;
        callAPI('locationDetails', locationDetailsCallback, {id: id});
        callAPIGet('locationAlertsGet', locationAlertsGetCallback, {locationId: id})
        callAPIGet('locationAlertsContacts', locationAlertsContactsCallback, {locationId: id})
        return () => {clearAllInputs()};
    }, []);

    useEffect(()=> {
        if (alerts?.length > 0 && alertsContacts && !setOnce.current) {
            setOnce.current = true;
            const alertsInputs = {};
            alerts.forEach((data)=> {
                if (data.kioskAlertTypeEnum === 1) {
                    alertsInputs.lowBills = updateAlertsInputs(data);
                } else if (data.kioskAlertTypeEnum === 2) {
                    alertsInputs.tilts = updateAlertsInputs(data);
                }
            });
            const dropdownList = {
                ['lowBills-first.id']: {list: 0,  labelProp: 'label', appendProp: 'name'},
                ['lowBills-second.id']: {list: 0, labelProp: 'label', appendProp: 'name'},
                ['tilts-first.id']: {list: 0,  labelProp: 'label', appendProp: 'name'},
                ['tilts-second.id']: {list: 0, labelProp: 'label', appendProp: 'name'}
            }
            const update = updateNestedInputData(alertsInputs, dropdownList, [alertsContacts]);
            update && setMasked(false);
        }
    }, [alerts, alertsContacts])

    useEffect(() => {
        changeInputErrors(null);
    }, [inputValues])

    const updateAlertsInputs = (data) => {
        const { contacts, ...newAlerts } = data;
        contacts.forEach((contact) => {
            const pre = contact.kioskAlertContactType === 0 ? 'first.' : 'second.';
            Object.entries(contact).forEach(([propKey, value]) => {
                newAlerts[pre + propKey] = value;
                const updatedContact = alertsContacts.find(c => c.id === contact.id);
                if (updatedContact) {
                    Object.entries(updatedContact).forEach(([propKey, value]) => {
                        newAlerts[pre + propKey] = value;
                    });
                }
            });
        });
        return newAlerts
    }

    useEffect(() => {
        if (!isValid(lowsFirstIndex) || !alertsContacts) {return}
        const newValues = deepCopy(inputValues);
        const newContact = alertsContacts[lowsFirstIndex]; 
        newValues['lowBills-first.phone'] = newContact.phone;
        newValues['lowBills-first.email'] = newContact.email;
        newValues['lowBills-first.name'] = newContact.name;
        newValues['lowBills-first.contactId'] = lowsFirstIndex;
        newValues['lowBills-first.id'] = newContact.id;
        if (!newContact.id || !newContact.phone) {
            newValues['lowBills-first.textAlert'] = false;
        }
        if (!newContact.id || !newContact.email) {
            newValues['lowBills-first.emailAlert'] = false;
        }
        changeInputValues(newValues);
    }, [lowsFirstIndex])

    useEffect(() => {
        if (!isValid(lowsSecondIndex) || !alertsContacts) {return}
        const newValues = deepCopy(inputValues);
        const second = alertsContacts[lowsSecondIndex];
        newValues['lowBills-second.phone'] = second.phone;
        newValues['lowBills-second.email'] = second.email;
        newValues['lowBills-second.name'] = second.name;
        newValues['lowBills-second.contactId'] = lowsSecondIndex;
        newValues['lowBills-second.id'] = second.id;
        if (!second.id || !second.phone) {
            newValues['lowBills-second.textAlert'] = false;
        }
        if (!second.id || !second.email) {
            newValues['lowBills-second.emailAlert'] = false;
        }
        changeInputValues(newValues);
    }, [lowsSecondIndex])

    useEffect(() => {
        if (!isValid(tiltsFirstIndex) || !alertsContacts) {return}
        const newValues = deepCopy(inputValues);
        const newContact = alertsContacts[tiltsFirstIndex]; 
        newValues['tilts-first.phone'] = newContact.phone;
        newValues['tilts-first.email'] = newContact.email;
        newValues['tilts-first.name'] = newContact.name;
        newValues['tilts-first.contactId'] = tiltsFirstIndex;
        newValues['tilts-first.id'] = newContact.id;
        if (!newContact.id || !newContact.phone) {
            newValues['tilts-first.textAlert'] = false;
        }
        if (!newContact.id || !newContact.email) {
            newValues['tilts-first.emailAlert'] = false;
        }
        changeInputValues(newValues);
    }, [tiltsFirstIndex])

    useEffect(() => {
        if (!isValid(tiltsSecondIndex) || !alertsContacts) {return}
        const newValues = deepCopy(inputValues);
        const second = alertsContacts[tiltsSecondIndex];
        newValues['tilts-second.phone'] = second.phone;
        newValues['tilts-second.email'] = second.email;
        newValues['tilts-second.name'] = second.name;
        newValues['tilts-second.contactId'] = tiltsSecondIndex;
        newValues['tilts-second.id'] = second.id;
        if (!second.id || !second.phone) {
            newValues['tilts-second.textAlert'] = false;
        }
        if (!second.id || !second.email) {
            newValues['tilts-second.emailAlert'] = false;
        }
        changeInputValues(newValues);
    }, [tiltsSecondIndex])

    const locationDetailsCallback = (data) => {
        if (data?.isSuccessful) {
            const details = data?.locationDetails;
            const types = data?.kioskTypes;
            deviceTypes.current = types;
            updateLocationTabs(types);
            setPageData(details);
            updateLocationHeader(details);
            updateLocationBreadcrumbs(details);
        } else {
            setPageData({})
        }
    }

    const locationAlertsGetCallback = (data) => {
        setAlerts(data?.kioskAlerts || []);
    }

    const locationAlertsContactsCallback = (data) => {
        setAlertsContacts(data?.kioskAlertContacts ? data.kioskAlertContacts : []);
    }

    const onDropdownCallback = (data, state) => {
        const index = data.index;
        if (data.section === 'lowBills') {
            state === 'first' ? setLowsFirstIndex(index) : setLowsSecondIndex(index);
        } else if(data.section === 'tilts') {
            state === 'first' ? setTiltsFirstIndex(index) : setTiltsSecondIndex(index);
        }
    }

    const onSubmit = (event, setLoading, type) => {
        setLoading(true);
        changeInputErrors({})
        
        const lowBillsData ={}
        const tiltsData={};
        Object.entries(inputValues).forEach(([key, value]) => {
            const parts = key.split('-');
            const section = parts[0];
            const line = parts[1]
            if (section === 'tilts') {
                tiltsData[line] = value;
            } else {
                lowBillsData[line] = value;
            }
        });

        const lowBillsContacts = [
            {kioskAlertContactType: 0}, 
            {kioskAlertContactType: 1}
        ];
        const tiltsContacts = [
            {kioskAlertContactType: 0}, 
            {kioskAlertContactType: 1}
        ];

        const newAlerts = [
            formatSubmitData(lowBillsData, lowBillsContacts),
            formatSubmitData(tiltsData, tiltsContacts)
        ]

        setAlerts(newAlerts)
        const params = {
            locationId: id,
            kioskAlerts: newAlerts
        }
        const props = {event: event, setLoading: setLoading}
        callAPI('locationAlertsSet', locationServicesSetCallback, params, props);
    }

    const formatSubmitData = (obj, contacts) => {
        const cleanObj = {};
        const allowedFields = ['emailAlert', 'id', 'kioskAlertUserType', 'name', 'textAlert'];
        Object.entries(obj).forEach(([key, value]) => {
            const parts = key.split('.');
            if (parts.length === 1) {
                cleanObj[key] = value;
            } else {
                const [section, field] = parts;
                if (allowedFields.includes(field)) {
                    if (section === 'first') {
                        contacts[0][field] = value;
                    } else if (section === 'second') {
                        contacts[1][field] = value;
                    }
                }
            }
        });
        contacts[0].kioskAlertUserType = parseInt(lowsFirstIndex);
        contacts[1].kioskAlertUserType = parseInt(lowsSecondIndex);    
        cleanObj.contacts = contacts;
        return cleanObj;
    }

    const locationServicesSetCallback = (data, props) => {
        props.setLoading(false);
        updateNotice(data, props);
    }

    return (
        <Page
            subPage={t.settings}
            tabs={locationTabs.tabs}
            contentClasses='grid location-details'
        >
            <Card label={t.kioskLowBillAlerts} classes='full'>
                <Checkbox label={t.active} section='lowBills' line='active' classes='third' noPermission={!permitted}/>
                <Input label={country.current === 'UG' ? t.alertForTenUGD : t.alertForTen} section='lowBills' line='lowRecycler10' classes='third'
                    type='max100' disabled={!inputValues?.['lowBills-active']} masked={masked} noPermission={!permitted}/>
                <Input label={country.current === 'UG' ? t.alertForTwentyUGD : t.alertForTwenty} section='lowBills' line='lowRecycler20' classes='third'
                    type='max100' disabled={!inputValues?.['lowBills-active']} masked={masked} noPermission={!permitted}/>

                <Dropdown label={t.primaryAlert} section='lowBills' line='first.id' classes='third' data={alertsContacts} callback={(data)=>{onDropdownCallback(data, 'first')}}
                    labelProp='label' appendProp='name' disabled={!inputValues?.['lowBills-active']} masked={masked} noPermission={!permitted}/>
                <Checkbox label={t.alertText} section='lowBills' line='first.textAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['lowBills-active'] || !inputValues?.['lowBills-first.id'] || !inputValues?.['lowBills-first.phone']}/>
                <Input label={t.phoneNumber} section='lowBills' line='first.phone' classes='quarter display-only' placeholder={t.none}
                    type='phone' masked={masked} noPermission={!permitted}/>
                <Checkbox label={t.alertEmail} section='lowBills' line='first.emailAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['lowBills-active'] || !inputValues?.['lowBills-first.id'] || !inputValues?.['lowBills-first.email']}/>
                <Input label={t.email} section='lowBills' line='first.email' classes='quarter display-only' placeholder={t.none}
                    masked={masked} noPermission={!permitted}/>
                
                <Dropdown label={t.secondaryAlert} section='lowBills' line='second.id' classes='third' data={alertsContacts} callback={(data)=>{onDropdownCallback(data, 'second')}}
                    labelProp='label' appendProp='name' disabled={!inputValues?.['lowBills-active']} masked={masked}noPermission={!permitted}/>
                <Checkbox label={t.alertText} section='lowBills' line='second.textAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['lowBills-active'] || !inputValues?.['lowBills-second.id'] || !inputValues?.['lowBills-second.phone']}/>
                <Input label={t.phoneNumber} section='lowBills' line='second.phone' classes='quarter display-only' placeholder={t.none}
                    type='phone' disabled={!inputValues?.['lowBills-active']} masked={masked} noPermission={!permitted}/>
                <Checkbox label={t.alertEmail} section='lowBills' line='second.emailAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['lowBills-active'] || !inputValues?.['lowBills-second.id'] || !inputValues?.['lowBills-second.email']}/>
                <Input label={t.email} section='lowBills' line='second.email' classes='quarter display-only' placeholder={t.none}
                    disabled={!inputValues?.['lowBills-active']} masked={masked} noPermission={!permitted}/>
            </Card>

            <Card label={t.kioskHardwareAlerts} classes='full'>
                <div className='full grid'>
                    <Checkbox label={t.active} section='tilts' line='active' classes='third' noPermission={!permitted}/>
                </div>
                <Dropdown label={t.primaryAlert} section='tilts' line='first.id' classes='third' data={alertsContacts} callback={(data)=>{onDropdownCallback(data, 'first')}}
                    labelProp='label' appendProp='name' disabled={!inputValues?.['tilts-active']} masked={masked} noPermission={!permitted}/>
                <Checkbox label={t.alertText} section='tilts' line='first.textAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['tilts-active'] || !inputValues?.['tilts-first.id'] || !inputValues?.['tilts-first.phone']}/>
                <Input label={t.phoneNumber} section='tilts' line='first.phone' classes='quarter display-only' placeholder={t.none}
                    type='phone' masked={masked} noPermission={!permitted}/>
                <Checkbox label={t.alertEmail} section='tilts' line='first.emailAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['tilts-active'] || !inputValues?.['tilts-first.id'] || !inputValues?.['tilts-first.email']}/>
                <Input label={t.email} section='tilts' line='first.email' classes='quarter display-only' placeholder={t.none}
                    masked={masked} noPermission={!permitted}/>
                
                <Dropdown label={t.secondaryAlert} section='tilts' line='second.id' classes='third' data={alertsContacts} callback={(data)=>{onDropdownCallback(data, 'second')}}
                    labelProp='label' appendProp='name' disabled={!inputValues?.['tilts-active']} masked={masked}noPermission={!permitted}/>
                <Checkbox label={t.alertText} section='tilts' line='second.textAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['tilts-active'] || !inputValues?.['tilts-second.id'] || !inputValues?.['tilts-second.phone']}/>
                <Input label={t.phoneNumber} section='tilts' line='second.phone' classes='quarter display-only' placeholder={t.none}
                    type='phone' disabled={!inputValues?.['tilts-active']} masked={masked} noPermission={!permitted}/>
                <Checkbox label={t.alertEmail} section='tilts' line='second.emailAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['tilts-active'] || !inputValues?.['tilts-second.id'] || !inputValues?.['tilts-second.email']}/>
                <Input label={t.email} section='tilts' line='second.email' classes='quarter display-only' placeholder={t.none}
                    disabled={!inputValues?.['tilts-active']} masked={masked} noPermission={!permitted}/>

                {permitted && <Button
                    classes={`green last sixth`}
                    type='submit'
                    onClick={(event, setLoading)=>onSubmit(event, setLoading, 2)}
                    >{t.submit}
                </Button>}
            </Card>
        </Page>
    )
}

export default LocationSettings;
